<template>
<div class="row">
  <div class="col-md-12">
    <card>
      <div slot="header">
        <h4 class="card-title">Histórico</h4>
        <p class="card-category">Pedidos entre fechas</p>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <fg-input label="Desde fecha">
            <el-date-picker v-model="datePicker1" type="date"
              placeholder="Desde fecha"
              :picker-options="pickerOptions1"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              @change="cambiaFecha">
            </el-date-picker>
          </fg-input>
        </div>
        <div class="col-lg-3">
          <fg-input label="Hasta fecha">
            <el-date-picker v-model="datePicker2" type="date"
              placeholder="Hasta fecha"
              :picker-options="pickerOptions1"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
              @change="cambiaFecha">
            </el-date-picker>
          </fg-input>
        </div>

        <div class="col-lg-3" v-if="this.$store.state.usuario.perfil!==1">
            <span style="color: gray; font-size: 12px;">USUARIO</span><br>
            <el-select size="large" placeholder="Elige usuario" v-model="usuario_id" @change="cambiaUsuario" style="padding-top: 4px;">
              <el-option v-for="usuario in usuarios" :value="usuario.id" :label="usuario.usuario" :key="usuario.id">
              </el-option>
            </el-select>

        </div>

      </div>


    </card>
  </div>


  <div class="col-md-6">
    <card title="Pedidos">
      <div slot="header">
        <h4 class="card-title">
          Pedidos de Usuario
        </h4>
        <div class="table-responsive table-full-width">
          <el-table :data="tableData" @row-click="cambioPedido">
            <el-table-column label="Fecha" property="fecha" width="150"></el-table-column>
            <el-table-column  v-if="this.$store.state.usuario.perfil==1" label="Comentario" property="comentario" width="300"></el-table-column>
            <el-table-column  v-if="this.$store.state.usuario.perfil!==1" label="Cliente" property="usunom" width="300"></el-table-column>
            <el-table-column label="P.ERP" property="pedido_erp" width="100"></el-table-column>
          </el-table>
        </div>
      </div>
    </card>
  </div>
  <div class="col-md-6">
    <card title="Líneas de pedido">
      <div slot="header">
        <h4 class="card-title">
          Líneas de Pedido
        </h4>
        <div class="table-responsive table-full-width">
          <el-table :data="tableData2">
            <el-table-column label="Cant" property="cantidad" width="100" style="text-align:right"></el-table-column>
            <el-table-column label="Nombre" property="nombre" width="150"></el-table-column>
            <el-table-column label="Marca" property="marca" width="100">
              <template slot-scope="scope">
                <l-switch v-model="scope.row.marca" on-text="SI" off-text="NO"></l-switch>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </card>
  </div>


</div>
</template>
<script>
  import { Checkbox, Radio, Switch as LSwitch } from 'src/components/index'
  import { Table, TableColumn, DatePicker, Select, Option } from 'element-ui'
  import axios from "axios";
  import crypt from "crypto-js";
  import AES from "crypto-js/aes";

  export default {
    components: {
      Checkbox,
      Radio,
      LSwitch,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data () {
      return {
        pickerOptions1: {
          shortcuts: [{
            text: 'Hoy',
            onClick (picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: 'Ayer',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: 'Una semana',
            onClick (picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }]
        },
        datePicker1: '',
        datePicker2: '',
        usuario: {},
        usuarios: {},
        usuario_id: '',
        familias: {},
        productos: {},
        tableData: [],
        tableData2: []
      }
    },
    created() {
      this.cargafamilias()
      this.cargaproductos()
      this.cargausuarios()
    },
    mounted() {
      if (this.$store.state.usuario.perfil==1) {
        this.usuario = this.$store.state.usuario
        this.usuario_id = this.$store.state.usuario.id
      }
    },
    methods: {
      cambioPedido(row, column, event) {
        console.log(row)
        var idpedido = row.id
        var mijson = {
          funcion: "lista",
          tabla: "pedlin",
          campos: "*",
          where: "pedidos_id='"+idpedido+"'",
          orden: ""
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))

          resp.forEach(function(valor,indice) {
            resp[indice].cantidad = valor.cantidad.replace(".",",")
            resp[indice].marca = ((valor.marca == 1) ? true : false)
          })

          this.tableData2 = resp
          console.log(resp)
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })

      },
      cargafamilias() {
        var mijson = {
          funcion: "lista",
          tabla: "familia",
          campos: "*",
          where: "",
          orden: ""
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.familias = resp
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      cargaproductos() {
        var mijson = {
          funcion: "lista",
          tabla: "producto",
          campos: "*",
          where: "",
          orden: ""
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.productos = resp
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      cargausuarios() {
        var mijson = {
          funcion: "lista",
          tabla: "usuarios",
          campos: "*",
          where: "perfil=1",
          orden: ""
        }
        var todos = {
          id: "99999",
          usuario: "TODOS",
          password: "",
          perfil: "9",
          cliente: "99999",
          serie: "9",
          recipiente: "1"
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.usuarios = resp
          this.usuarios.unshift(todos)
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      cambiaUsuario(id) {
        var misusuarios = this.usuarios
        var usuario = {}
        misusuarios.forEach( function(valor, indice) {
          if (valor.id==id) {
             usuario = valor
          }
        })
        this.usuario = usuario
        this.usuario_id = id
        if (this.datePicker1!=='' && this.datePicker2!=='' && this.usuario_id!=='') {
          console.log("ya puedo cargar lista")
          this.cargaLista()
        }
      },
      cambiaFecha() {
        if (this.datePicker1!=='' && this.datePicker2!=='' && this.usuario_id!=='') {
          console.log("ya puedo cargar lista")
          this.cargaLista()
        }

      },
      cargaLista() {
        var misusuarios = this.usuarios
        var whe = ""
        if (this.usuario.usuario=="TODOS") {
          whe = "DATE(fecha)>='"+this.datePicker1+"' and DATE(fecha)<='"+this.datePicker2+"'"
        } else {
          whe = "DATE(fecha)>='"+this.datePicker1+"' and DATE(fecha)<='"+this.datePicker2+"' and usuario_id='"+this.usuario_id+"'"
        }
        var mijson = {
          funcion: "lista",
          tabla: "pedidos",
          campos: "*",
          where: whe,
          orden: ""
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))

          resp.forEach(function(valor,indice) {
            var solofecha = valor.fecha.slice(0,10)
            var mifecha = solofecha.substring(8)+"/"+solofecha.substring(5,7)+"/"+solofecha.substring(0,4)
            resp[indice].fecha = mifecha

            var usuid = valor.usuario_id
            misusuarios.forEach( function(valor2, indice2) {
              if (valor2.id==usuid) {
                 resp[indice].usunom = valor2.usuario
              }
            })

          })

          this.tableData = resp
          console.log(resp)
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      }
    }
  }
</script>
<style>
</style>
